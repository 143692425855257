.MuiPaper-elevation4 {
    box-shadow: none;
    border-radius: 0;
}

[class$="-MUIDataTableToolbar-actions"] {
    width:100%
}

button[aria-label="Search"] {
    border-radius: 50%; /* Makes the button round */
    border: 1px solid #C0C5CD; /* Grey border */
    padding: 6px; /* Adds some padding inside the button */
    cursor: pointer; /* Changes the cursor to a pointer when hovering over the button */
    float: left;
    margin:unset;
    margin-left: -20px;
}

[class$="-MUIDataTableSearch-main"] svg[data-testid="SearchIcon"] {
    display: none;
}

.MuiTableRow-head th {
    background-color: var(--Layout-V2-Table-Header, #F3F5F8); /* Your desired background color */
    color: var(--Layout-V2-Secondary-Title, #465366);
    font-size: 14px;
    font-weight: 400 !important;
    line-height: 140%;
}

.mui-datatable-headerCell {
    background-color: transparent !important; /* Remove any background change on hover */
    cursor: default !important; /* Change cursor to default to avoid the pointer hand */
  }
  
  .MapActionBtn {
    border: var(--Layout-V2-Secondary-Title, #465366);
    text-transform: none !important;
    margin-left: 1px;
    padding-left: 0px;
    padding-right: 0px;
  }