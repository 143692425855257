.UserSelect .MuiAutocomplete-inputRoot {
    padding: unset;
    /* background-color: #f0f8ff; */
    border: unset !important;
    color: #0F42BC !important;
}

.UserSelect input.MuiAutocomplete-inputFocused {
    padding: unset !important;
    width: 75% !important;
    border: unset !important;
    /* background-color: #f0f8ff; */
    color: #0F42BC;
    font-weight: 500;
}

.UserSelect .MuiAutocomplete-inputRoot {
    padding: unset;
    border: unset !important;
}

.UserSelect .MuiFormControl-root {
    float: left;
    margin-left: 3px !important;
    border: unset !important;
}