.ruleGroup .ruleGroup .ruleGroup-addGroup {
  display: none;
}

.queryBuilder .ruleGroup-header .MuiButtonBase-root {
  background-color: #4599FA !important;
  box-shadow: none;
  margin-left: 0px;
}


.ruleGroup {
  background-color: white !important;
  border-style: unset !important;
}

.ruleGroup-addGroup {
  display: none !important;
}

/* Style for the remove button at the end of each rule */
.queryBuilder .rule .rule-remove {
  background-color: #000;
  /* Blue background */
  border: none;
  /* Remove default border */
  color: white;
  /* White text */
  min-width: 28px;
  /* Minimum width */
  box-shadow: none;
  /* Remove any box shadow */
  margin-left: 8px;
  /* Add space before the remove button */
  cursor: pointer;
  /* Indicate that the button is clickable */
  height: 28px;
  width: unset;
  border-radius: 50%;
  font-size: x-large;
  padding: 2px 8px;
  padding-bottom: 8px;
}

.betweenRules::before {
  border: unset;
}

.queryBuilder-branches .rule::before,
.queryBuilder-branches .rule::after,
.queryBuilder-branches .ruleGroup .ruleGroup::before,
.queryBuilder-branches .ruleGroup .ruleGroup::after {
  border: unset !important;
}

.queryBuilder-branches .ruleGroup-header button{
  margin-left: 10px;
}

.queryBuilder-branches .betweenRules::before {
  border: unset !important;
}

.query-builder-container {
  background-color: white;
  border: unset;
  margin-top: 5px;
  text-align: center;
  display: flex;
  width: "80%";
  justify-content: center;
  /* Center children horizontally */
  align-items: center;
  /* Center children vertically */
  min-height: 100vh;
}

.query-builder-label {
  margin-bottom: 3px;
  /* Adds space between the label and the QueryBuilder */
  font-weight: bold;
  /* Makes the text bold */
  margin-top: 10px;
  font-size: large;
  font-family: "Roboto", sans-serif;
  margin-left: 5px;
  /* Add more styling as needed */
}

.ruleGroup-header button {
  margin-left: 15px;
}

.react-querybuilder .addRuleClass {
  text-transform: none !important;
}

.ruleGroup-header button {
  text-transform: none !important;
}

/* If you want to apply it globally to all buttons in react-querybuilder */
.react-querybuilder button {
  text-transform: none !important;
}

/* If using Material-UI components inside react-querybuilder */
.react-querybuilder .MuiButton-root {
  text-transform: none !important;
}

.addGroup {
  text-transform: none !important;
  border-color: lightgray !important;
  color: gray !important;
}